<template>
  <div class="interest">
    <img style="display: block" src="../../assets/interest.png" alt="" />
    <downBtn
      :topShow="true"
      :isWx="isWx"
      @down="download"
      @wechatShow="wechatShow"
    ></downBtn>
    <!-- <downBtn :bottomShow="true" :topShow="true" @down="download"></downBtn> -->
  </div>
</template>

<script>
import downBtn from "@/components/downBtn.vue";
import mixin from "@/utils/mixin";
export default {
  mixins: [mixin],
  name: "interest",
  components: {
    downBtn,
  },
  props: {},
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style scoped lang="scss">
.interest {
  img {
    width: 100%;
  }
}
</style>
